
import http from '../../http';
export function getList (params) {
    return http({
        url: "/api/project/projectPage",
        method: "get",
        params:params
    })
}
export function getDetial (params) {
    return http({
        url: "/api/project/project",
        method: "get",
        params:params
    })
}
export function editReport (data) {
    return http({
        url: "/api/reportStats/takeOff",
        method: "put",
        data:data
    })
}
export function discardReport (data) {
    return http({
        url: "/api/reportStats/takeOff",
        method: "delete",
        data:data
    })
}
//申请揭榜
export function projectItem (data) {
    return http({
        url: "/api/project/projectItem",
        method: "put",
        data:data
    })
}
export function editProjectItem (data) {
    return http({
        url: "/api/project/projectItem",
        method: "post",
        data:data
    })
}

//立项项目审批（成单项目审批也用此接口）
export function auditStep1 (data) {
    return http({
        url: "/api/base/audit",
        method: "post",
        data:data
    })
}

//项目结题列表
export function projectConclusionPage (params) {
    return http({
        url: "/api/project/projectConclusionPage",
        method: "get",
        params:params
    })
}
//团队变更审核列表
export function auditProjectTeamChangeList (params) {
    return http({
        url: "/api/project/auditProjectTeamChangeList",
        method: "get",
        params:params
    })
}

export function baseAudit (params) {
    return http({
        url: "/api/base/audit",
        method: "POST",
        params:params
    })
}



//揭榜详情
export function jbDetail (params) {
    return http({
        url: "/api/project/projectItem",
        method: "get",
        params:params
    })
}
