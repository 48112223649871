<template>
  <div>
    <!-- title 组件 -->
    <div class="content-tile">
      <div class="su-title">
        <i class="el-icon-document-copy"></i>
        <span>项目成员变更审批</span>
      </div>

    </div>
    <!--tabs 组件 -->
    <common-tabs
        :tabsProp="tabsProp"
        @tabsClick="tabsClick"
    />
    <!--table 组件 -->
    <common-table
        :tableData="tableData"
        :tableProp="tableProp"
        @pageCurrentChange="pageCurrentChange"
        @detileApproval="detileApproval"
        @passOrder="passOrder"
    />
    <el-dialog
        title="审批"
        :visible.sync="dialogFormVisible"
        :append-to-body="true"
    >
      <el-form :model="form">

        <el-form-item label="" :label-width="formLabelWidth">
          <el-radio-group v-model="form.status">
            <el-radio label="1">通过</el-radio>
            <el-radio label="2">不通过</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="审批意见" :label-width="formLabelWidth">
          <el-input type="textarea" v-model="form.fail_reasons"></el-input>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addDialogSub">确 定</el-button>
      </div>
    </el-dialog>


    <el-dialog
        title="项目成员变更"
        :visible.sync="dialogVisibleStaff"
        width="80%"
        :append-to-body="true">

      <div>
        <common-table
            :tableData="jbData.addDialogShowData.member_json"
            :tableProp="jhmxtableProp"
        />
        <div style="padding:60px  0 0;display: flex;justify-content: right">
          <el-button type="primary" @click="close">确定</el-button>
        </div>
      </div>
    </el-dialog>



  </div>
</template>
<script>
// import {getAppointProjectPage} from "../../../api/module/approval/common";
import {auditProjectTeamChangeList, baseAudit} from "../../../api/module/project/project_func";
export default {
  components: {},
  props: [],
  data() {
    return {
      dialogVisibleStaff:false,
      //揭榜
      jbData: {
        isDialog: false,//dialog是否显示
        title: '揭榜',// dialog 标题
        top: '50px', // dialog 离浏览器top
        width: '1335px', // dialog 长度，可以是百分比
        labelWidth: '160px', // label 长度
        //dialog 显示数据
        addDialogShowData: {
          role_name: '',
          user_id: '',
          project_id: '',
          dev_hope_red_rate: '',
          dev_hope_grow_rate: '',
          deployment_req: '',
          mission_req: '',
          cycle_rp: '',
          task_completion_rp: '',
          other_reward: '',
          member_json: [],
          attachment_json:[],
        },
      },
      jhmxtableProp: {
        index: false,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: false, //是否分页
        statusName: 'status',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
        },
        table: [{
          title: '项目角色',
          field: 'role_name',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '部门',
          field: 'department_name',
          isWidth: false,
          width: '140px',
          headerAlign: 'center',
          align: 'center',
          disabled:true,
          tooltip: false,
          type: 0,
        }, {
          title: '岗位',
          field: 'station_name',
          isWidth: false,
          width: '140px',
          headerAlign: 'center',
          align: 'center',
          disabled:true,
          tooltip: false,
          type: 0,
        }, {
          title: '姓名',
          field: 'name',
          isWidth: false,
          width: '150px',
          headerAlign: 'center',
          align: 'center',
          disabled:true,
          tooltip: false,
          type: 0,
        }, {
          title: '任务名称',
          field: 'task_name',
          isWidth: false,
          width: '150px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '计划开始时间',
          field: 'start_time',
          isWidth: false,
          width: '150px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 4,
        }, {
          title: '计划结束时间',
          field: 'end_time',
          isWidth: false,
          width: '150px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 4,
        }, {
          title: '计划参与度(%)',
          field: 'join_rate',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '绩效',
          field: 'achievements',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }],

      },



      //tabs 数据源
      tabsProp: {
        active: '0',
        method: 'tabsClick', //点击tabs方法名
        keywords: '',//搜索的值
        keyWordMethod: 'keyWordMethod',// 搜索方法名
        keywordsShow: false,//搜索框是否显示
        isHighKeyWord: false,//是否有高级搜索
        highKeyWordDialogMethod: 'highKeyWordMethod',//高级搜索点击确定*/
        //高级搜索的字段
        heighKewWordField: {status: '', project_title: '', type_id: '', name: '', customer: '',},
        //tabs 标题
        tabsData: [{
          label: '待审批',
          name: "0",
          total: 0,
        },{
          label: '已通过',
          name: "1",
          total: 0,
        },{
          label: '未通过',
          name: "2",
          total: 0,
        },{
          label: '重新审核',
          name: "3",
          total: 0,
        }]
      },
      //table 数据源
      tableProp: {
        index: false,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: true, //是否分页
        statusName: 'status',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
        },
        table: [{
          title: '项目编号',
          field: 'project_sn',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '项目名称',
          field: 'project_title',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '项目类型',
          field: 'type_id',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },{
          title: '项目金额',
          field: 'all_price',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '提交部门',
          field: 'department_name',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },  {
          title: '计划成单时间',
          field: 'start_time',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 4,
        }, {
          title: '计划交付时间',
          field: 'end_time',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 4,
        },{
          title: '提交人',
          field: 'memberList',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '操作',
          isWidth: false,
          width: '220px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 20,
          tableOperate: [{
            name: '变更详情',
            type: 'primary',
            method: 'detileApproval',
            status: '20000',
            key:'detail',
            id: 20000,
          }, {
            name: '审批',
            type: 'danger',
            method: 'passOrder',
            key:'check',
            status: '20000',
            id: 20000,
          }],
        }],
      },
      dialogTableVisible: false,
      dialogFormVisible: false,
      formLabelWidth:'120px',
      //表格数据
      tableData: [],
      //addDialog提交数据
      subData: {},
      form: {
        status: "1",//1通过2未通过
        fail_reasons:"",
        id:0
      },
    };
  },
  created() {
    this.initLoad();
  },
  mounted() {
  },
  methods: {
    //表格初始化
    initLoad() {
      this.$store.state.loading = true;
      let params = {
        page: this.tableProp.page.current_page,
        audit_status:this.tabsProp.active,
      }
      auditProjectTeamChangeList(params).then((result) => {
        let res = result.data;
        this.tableProp.page.current_page = res.current_page;
        this.tableProp.page.total = res.total;
        this.tableProp.page.size = res.per_page;
        this.tableData = res.data
        for (let i = 0; i < this.tableData.length; i++) {
          let memberList = '';
          if(this.tableData[i].user.length!=0) {
            memberList+=this.tableData[i].user.name
          }else {
            memberList = '暂无人提交';
          }
          //提交部门
          if(this.tableData[i].submit_department_name!=null){
            this.tableData[i].department_name = this.tableData[i].submit_department_name
            memberList = this.tableData[i].submit_user_name
          }else {
            this.tableData[i].department_name = '暂未记录'
            memberList = '暂未记录'
          }
          this.tableData[i].memberList = memberList;
          this.tableData[i].type_id = this.tableData[i].project_type.type_name
          this.tableData[i].all_price = (this.tableData[i].all_price/10000).toFixed(2)+"万"

        }
      })
    },
    //tabs 切换点击
    tabsClick(tab) {
      //this.tabsProp.active //tabs 当前的值
      this.tableProp.page.current_page = 1;
      this.initLoad()
      console.log(tab.name)
    },

    //table 确认成单 按钮点击
    passOrder(row) {
      console.log(row)
      this.form.id = row.audit_id
      this.dialogFormVisible  = true;
      // this.form.id = row.audit_info.id
      // this.form.consent_information_json.market_grow_value = row.market_grow_value
      // this.form.consent_information_json.market_red_rate = row.market_red_rate*100
      // this.form.fail_reasons = row.audit_msg
      // this.form.publish_reward = row.publish_reward

    },

    //table 详情 按钮点击
    detileApproval(row, index) {
      console.log(row)
      console.log(index)
      this.jbData.addDialogShowData.member_json =row.project_plan_member_prepare
      //表格弹窗
      this.dialogVisibleStaff = true

    },
    close(){
      this.dialogVisibleStaff = false

    },
    // addDialog 点击确定
    addDialogSub() {
      let data = {...this.form}
      baseAudit(data).then(resul => {
        this.dialogFormVisible = false;
        this.$message({
          message: resul.msg,
          type: 'success'
        });
        this.initLoad();
      })
    },
    //table 分页 点击当前页
    pageCurrentChange(val) {
      this.tableProp.page.current_page = val
      console.log('当前页:' + val);
      this.initLoad();
    },
  },
};
</script>
<style lang="scss" scoped>
.content-tile {
  @include flex(nowrap, space-between, center);
  background: $title-bg;
  border: 1px solid $title-bg;
  padding: 8px;
  margin-bottom: 12px;

  .su-title {
    font-weight: bold;
    @include flex();

    i {
      font-size: 16px;
      color: $title-icon;
      font-weight: bold;
      margin-right: 8px;
    }
  }
}
</style>
